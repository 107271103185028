import React from "react";
import PortfolioClient from "./PortfolioClient";
import { CompasBreakLine } from "utils";
import { content, portfolio } from "staticContent/more/portfolio";

const portfolioObjs = portfolio.map((item, index) => (
  <React.Fragment key={index}>
    <PortfolioClient {...item} />
    <CompasBreakLine />
  </React.Fragment>
));

const Portfolio = () => (
  <>
    <section className="section hero portfolio">
      <h1 className="hero__banner-text">{content.pageTitle}</h1>
    </section>
    <div>
      <p className="portfolio__short-note">{content.pageNote}</p>
    </div>
    <section className="section portfolio-blocks-container">
      <div className="portfolio-block__blocks-wrapper">{portfolioObjs}</div>
    </section>
  </>
);

export default Portfolio;
