import React from "react";
import Layout from "utils/Layout";
import Portfolio from "components/Portfolio";
import "styles/components/portfolio.scss";
import { content } from "staticContent/more/portfolio";

const IndexPage = () => (
  <Layout subTitle={content.pageTitle}>
    <Portfolio />
  </Layout>
);

export default IndexPage;
