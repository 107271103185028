import React from "react";
import { Link } from "gatsby";

const UsedProducts = ({ imgs, title, pageWidth }) => {
  if (imgs.length === 0) return false;

  const productsSet = imgs.map(({ path, pathMobile, src, alt }, index) => (
    <Link
      to={pageWidth > 600 ? path : pathMobile}
      className="block__item-logo"
      key={index}
    >
      <img src={src} loading="lazy" alt={alt} />
    </Link>
  ));

  return (
    <>
      <h2 className="block__title">{title}</h2>
      <div className={"block__logo-items block__logo-items--products"}>
        {productsSet}
      </div>
    </>
  );
};

export default UsedProducts;
