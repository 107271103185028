import React, { useState, useEffect } from "react";
import UsedProducts from "./UsedProducts";
import { Button } from "utils";
import { portfolioImg } from "constants/portfolio.constants";
import { content } from "staticContent/more/portfolio";

const { productsNote, btn } = content;

const PortfolioClient = ({ title, note, link, picKey, products }) => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  return (
    <div className="portfolio-block">
      <div className="portfolio-block__description">
        <h2 className="block__title">{title}</h2>
        <p className="block__text">{note}</p>
        {link && (
          <Button
            children={products.length !== 0 ? btn.product : btn.app}
            className="block__btn"
            onClick={() => window.open(link, "_blank").focus()}
          />
        )}
      </div>
      <div className="portfolio-block__pic">{portfolioImg[picKey]}</div>
      <div className="portfolio-block__products">
        <UsedProducts
          imgs={products}
          title={productsNote}
          pageWidth={pageWidth}
        />
      </div>
    </div>
  );
};

export default PortfolioClient;
