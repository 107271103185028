import { productsLogo } from "routes/picPath";

const content = {
  pageTitle: "Portfolio",
  pageNote:
    "Z satysfakcją odnotowujemy, że lista naszych Klientów nieustannie się powiększa. Obecnie współpracujemy z wieloma instytucjami zapewniając im aplikacje do użytku wewnętrznego oraz geoportale. Oto przykłady naszych wdrożeń:",
  techNote: "Wykorzystane technologie",
  productsNote: "Produkty w pakiecie",
  btn: {
    product: "przejdź do portalu",
    app: "przejdź do sklepu",
  },
};

const portfolio = [
  {
    title: "Urząd Miasta w Gliwicach ",
    note: "Urząd Miasta w Gliwicach jako pierwszy wdrożył System Zintegrowanej Obsługi Planowania Przestrzennego automatyzując proces opracowywania i publikowania planów zagospodarowania przestrzennego. Publiczny geoportal UM Gliwice został zbudowany w oparciu o aplikację Geoportal Toolkit (GPT). Aktualnie udostępnia on informacje podzielone na 11 profili tematycznych.",
    link: "https://geoportal.gliwice.eu/gpt4/?profile=756",
    picKey: "gliwice",
    products: [
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.szopp,
        alt: "logo szopp",
        path: "/more/products#szopp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Urząd Miasta w Krakowie",
    note: "Wdrożenie w Urzędzie Miasta w Krakowie to doskonały przykład wysokiej wydajności naszych aplikacji. Każdego dnia blisko 2 000 użytkowników aktywnie korzysta z aplikacji mapowej Geoportal Toolkit (GPT). Internetowy Serwer Danych Przestrzennych (ISDP) stanowi centralną hurtownię danych przestrzennych – umożliwiającą pobieranie danych PZGiK oraz zapis danych na mapie z aplikacji obsługujących takie tematy, jak: pozwolenia na budowę, dane podatkowe czy inwestycje miejskie itp. Aplikacja PIECE została wdrożona po raz pierwszy w 2015 roku w Wydziale Kształtowania Środowiska w Urzędzie Miasta Krakowa. Przy jej pomocy obsługiwanych jest kilka tysięcy wniosków o dofinansowanie.",
    picKey: "krakow",
    link: "",
    products: [
      {
        src: productsLogo.eoz,
        alt: "logo eoz",
        path: "/more/products#eoz",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.piece,
        alt: "logo piece",
        path: "/more/products#piece",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Urząd Miasta w Mysłowicach ",
    note: "Urząd Miasta w Mysłowicach jako pierwszy wdrożył Aplikację podatkową umożliwiającą uszczelnienie systemu poboru podatku od nieruchomości. W codziennej pracy pracowników UM wspiera również SOWA, GPT i ISDP. Portal Zintegrowanego Systemu Informacji Przestrzennej Miasta Mysłowice tworzy m.in. geoportal stworzony w oparciu o aplikację Geoportal Toolkit (GPT) oraz portal metadanych.",
    link: "https://zsip.myslowice.pl/geoportale/geoportal/ ",
    picKey: "myslowice",
    products: [
      {
        src: productsLogo.appPod,
        alt: "logo appPod",
        path: "/more/products#appPod",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Urząd Miasta w Kielcach",
    note: "Wdrożenie w Urzędzie Miasta w Kielcach jest najlepszym przykładem uniwersalności oraz wydajności aplikacji SOWA. UM Kielce wprowadził do SOWY ponad 216 000 pism w ponad 161 000 sprawach. Moduł Dynamicznych ewidencji SOWA daje pełną swobodę w tworzeniu rejestrów i ewidencji w zakresie wyglądu formularzy obiektów i wyszukiwania oraz zakresu danych gromadzonych w ramach ewidencji. Wykorzystując tę zaletę naszej aplikacji UM Kielce prowadzi 535 rejestrów oraz 67 ewidencji w aplikacji SOWA. Miejski System Informacji Przestrzennej w Kielcach został zbudowany w oparciu o aplikację Geoportal Toolkit (GPT). Aktualnie udostępnia on informacje podzielone na 9 profili tematycznych.",
    link: "https://gis.kielce.eu/",
    picKey: "kielce",
    products: [
      {
        src: productsLogo.sowa,
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Urząd Miasta w Bytomiu",
    note: "System Obsługi Wniosków Administracyjnych (SOWA) powstał kilkanaście lat temu na potrzeby Wydziału Architektury Urzędu Miasta w Bytomiu. Od tego czasu SOWA została wdrożona w blisko 30 jednostkach samorządu terytorialnego, aby wspierać urzędników w ich codziennej pracy. Internetowy Serwis Bytomskiej Infrastruktury Informacji Przestrzennej tworzy 8 publicznych profili tematycznych stworzonych przy pomocy aplikacji Geoportal Toolkit (GPT).",
    link: "http://sitplan.um.bytom.pl/iuip/mapa.php",
    picKey: "bytom",
    products: [
      {
        src: productsLogo.sowa,
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Urząd Miasta w Szczecinie",
    note: "Wdrożenie w Urzędzie Miasta w Szczecinie jest przykładem uniwersalności aplikacji Geoportal Toolkit (GPT). Portal Systemu Informacji Przestrzennej Miasta Szczecin udostępnia dane przestrzenne podzielone na 30 profili tematycznych, zapowiadając publikację kolejnych. Ogromną zaletą Szczecińskiego Geoportalu jest możliwość wyświetlania go w wersji dedykowanej dla urządzeń mobilnych z ekranem dotykowym.",
    link: "http://geoportal.szczecin.pl:9090/gpt4/",
    picKey: "szczecin",
    products: [
      {
        src: productsLogo.sowa,
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.gpt,
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    title: "Dom Współpracy Polsko-Niemieckiej",
    note: `Aplikacja e-historie to niekonwencjonalny sposób na odkrywanie faktów historycznych i ciekawostkek regionalnych nawiązujących do górnośląskich tradycji – również na lekcjach języka niemieckiego. Na aplikację składają się aktualnie dwa moduły:
        \nPuzzle – prezentują zdjęcia związane ze zwyczajną codziennością oraz tradycjami Górnego Śląska,
        \nQuiz mapowy – mapa Śląska z naniesionymi miejscowościami, która pozwoli nauczyć się ich nazw w językach polskim i niemieckim oraz zapoznać się bliżej z ich historią i zabytkami.
        \nAplikacja e-historie jest natywną aplikacją napisaną w języku Kotlin 1.4, zgodną z systemami Android 7–11 i dostępną bezpłatnie w sklepie Google Play. Aplikacja będzie stale rozwijana o nowe moduły i funkcjonalności.`,
    link: "https://play.google.com/store/apps/details?id=pl.hyperview.ehistoriepl",
    picKey: "dom",
    products: [],
  },
];

export { content, portfolio };
