import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const portfolioImg = {
  bytom: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/bytom.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  dom: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/dom.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  gliwice: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/gliwice.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  kielce: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/kielce.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  krakow: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/krakow.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  myslowice: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/myslowice.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
  szczecin: (
    <StaticImage
      className=""
      src="../../assets/images/portfolio/szczecin.png"
      alt="zrzut ekranu wdrożenia produktu"
      placeholder="tracedSVG"
    />
  ),
};

export { portfolioImg };
